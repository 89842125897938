import "./Ekstrakurikuler.css";

function Ekstra() {
  return (
    <section className="ekstra-section">
      <div className="ekstra-all-container">
      <div className="ekstra-container">
        <div className="sub-ekstra-container"  data-aos="fade-up">
          <div className="header-ekstra-container">
            <div className="title-ekstra">
              <h3>Ekstrakurikuler</h3>
              <small>
                Ekstrakurikuler dapat membantu peserta didik mendapatkan
                pengetahuan, keterampilan, serta membantu membentuk karakter
                peserta didik sesuai dengan minat dan bakat masing-masing.
              </small>
            </div>
          </div>
          <div className="box-container">
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/03/05/option.png"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>OPTION</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/08/19/output-onlinepngtools_1.png"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>KERANI</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/03/05/Desain_tanpa_judul_1.png"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>IKLIM</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/03/05/1631242770911218-0.jpg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Rokhris</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/03/05/pbchaptoen_2.png"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Chaptoen</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/WhatsApp_Image_2022-07-14_at_21.45.53.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Futsal ten</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/03/28/IMG_2946.JPG"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>SWAKAPASI</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/06/05/53183088_790063984695573_8116544013894418432_n.jpg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Volten</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/06/30/Untitled.jpg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>SATKOPAS</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/WhatsApp_Image_2022-07-14_at_16.26.54.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>AKSI</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/WhatsApp_Image_2022-07-14_at_19.00.13.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Basten</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/LOGO_KIR-removebg-preview.png"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>KIR</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/WhatsApp_Image_2022-07-14_at_18.12.46.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Pencak Silat</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/WhatsApp_Image_2022-07-14_at_19.15.05.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>PIK Remaja</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/WhatsApp_Image_2022-07-14_at_21.04.46.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Marching Band</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/WhatsApp_Image_2022-07-14_at_22.11.27.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>LLC</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/14/20220622_130939.jpg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>Mugiwara</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>
          <div class="box">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/ekskul/2022/07/16/WhatsApp_Image_2022-07-15_at_11.13.28.jpeg"
              alt="Image Description"
              class="box-image"/>
            <div class="box-description">
             <h3>WAKAMACA</h3>
             <p>Official Practice of Technology Information and Communication</p>
             <hr></hr>
             <h4>Ketua umum: Djoko haryo baskoro</h4>
            </div>
          </div>

          </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ekstra;
