// NewWebsite.js
import React from 'react';

function Galerifoto() {
  return (
    <div>
      <h1>Welcome to the Other Website</h1>
      <p>This is another page in the same React app.</p>
    </div>
  );
}

export default Galerifoto;
