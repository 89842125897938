import React, { useState, useEffect } from "react";
import "./Navbar.css";

function Navbar() {
  const [showInput, setShowInput] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuVisible, setSubmenuVisible] = useState({
    htmlcss: false,
    more: false,
    js: false,
  });

  // Toggle search box visibility
  const toggleSearch = () => {
    setShowInput((prev) => !prev);
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setMenuOpen((prev) => !prev);
  };

  // Toggle submenu visibility
  const toggleSubmenu = (menu) => {
    setSubmenuVisible((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  useEffect(() => {
    // Handle closing menu
    if (!menuOpen) {
      setSubmenuVisible({
        htmlcss: false,
        more: false,
        js: false,
      });
    }
  }, [menuOpen]);

  
  return (
    <nav>
      <div className={`navbar ${showInput ? "showInput" : ""}`} data-aos="fade-down"  data-aos-duration="900">
        <i className="bx bx-menu" onClick={toggleSidebar}></i>
        <div className="logo">
          <a href="/">SMAN 10 BEKASI</a>
        </div>
        <div className="nav-links" style={{ left: menuOpen ? "0" : "-100%" }}>
          <div className="sidebar-logo">
            <span className="logo-name">CodingLab</span>
            <i className="bx bx-x" onClick={toggleSidebar}></i>
          </div>
          <ul className="links">
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#">Profile</a>
              <i
                className={`bx bxs-chevron-down htmlcss-arrow arrow ${
                  submenuVisible.htmlcss ? "open" : ""
                }`}
                onClick={() => toggleSubmenu("htmlcss")}
              ></i>
              <ul
                className={`htmlCss-sub-menu sub-menu ${
                  submenuVisible.htmlcss ? "show1" : ""
                }`}
              >
                <li>
                  <a href="#">Struktur Organisasi</a>
                </li>
                <li>
                  <a href="#">Ekstrakurikuler</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Gallery</a>
              <i
                className={`bx bxs-chevron-down js-arrow arrow ${
                  submenuVisible.js ? "open" : ""
                }`}
                onClick={() => toggleSubmenu("js")}
              ></i>
              <ul
                className={`js-sub-menu sub-menu ${
                  submenuVisible.js ? "show3" : ""
                }`}
              >
                <li>
                  <a href="#">Foto</a>
                </li>
                <li>
                  <a href="#">Video</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Info</a>
              <i
                className={`bx bxs-chevron-down js-arrow arrow ${
                  submenuVisible.js ? "open" : ""
                }`}
                onClick={() => toggleSubmenu("js")}
              ></i>
              <ul
                className={`js-sub-menu sub-menu ${
                  submenuVisible.js ? "show3" : ""
                }`}
              >
                <li>
                  <a href="#">Berita</a>
                </li>
                <li>
                  <a href="#">Pengumuman</a>
                </li>
                <li>
                  <a href="#">Kontak</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Application</a>
              <i
                className={`bx bxs-chevron-down js-arrow arrow ${
                  submenuVisible.js ? "open" : ""
                }`}
                onClick={() => toggleSubmenu("js")}
              ></i>
              <ul
                className={`js-sub-menu sub-menu ${
                  submenuVisible.js ? "show3" : ""
                }`}
              >
                <li>
                  <a href="#">Pengumuman Kelulusan</a>
                </li>
                <li>
                  <a href="#">Ujian Online</a>
                </li>
                <li>
                  <a href="#">SMANTEN Store</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#">Links</a>
              <i
                className={`bx bxs-chevron-down js-arrow arrow ${
                  submenuVisible.js ? "open" : ""
                }`}
                onClick={() => toggleSubmenu("js")}
              ></i>
              <ul
                className={`js-sub-menu sub-menu ${
                  submenuVisible.js ? "show3" : ""
                }`}
              >
                <li>
                  <a href="#">Instagram</a>
                </li>
                <li>
                  <a href="#">Kemendikbud</a>
                </li>
                <li>
                  <a href="#">Disdik Jabar</a>
                </li>
                <li>
                  <a href="#">Portal PPDB</a>
                </li>
                <li>
                  <a href="#">Bank Soal</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
