import "./Sambutan.css";

function Sambutan() {
  return (
    <section>
      <div className="sambutan-container">
        <div className="Sambutan-sub-container" data-aos="flip-down" data-aos-duration="800">
          <div className="img">
            <img
              src="https://github.com/Luciuus/Login/blob/main/images/Logo.jpeg?raw=true"
              className="image-master"
              alt="image-master"
            />
            <h4>Hj.Dra.Mukaromah.Mpd</h4>
        </div>
            <div className="scroll-box">
              <p>
                <p>Assalamalaikum wr.wb. </p>
                <p>Selamat datang di website SMAN 10 Kota
                Bekasi.</p>
                <p>Alhamdulillah, puji syukur kehadirat Allah SWT, Pencipta
                seluruh semesta alam, atas kemurahanNya kami hadir menyapa
                publik dalam rangka berbagi informasi seputar dunia pendidikan.
                </p>
                <p>Kehadiran website SMAN 10 Kota Bekasi menjadi bagian yang tak
                dapat dipisahkan terhadap konstelasi institusi pendidikan.
                </p>
                <p>Keberadaannya sangat penting agar mempermudah seluruh civitas
                pendidikan, baik pendidik/tenaga kependidikan, peserta didik,
                orang tua dan masyarakat dapat mengakses informasi dan
                memperluas jejaring.
                </p>
                <p>Derasnya perkembangan teknologi informasi
                saat ini menjadi tantangan tersendiri dalam dunia pendidikan.
                Untuk itu rekayasa teknologi menjadi pilihan agar melahirkan
                generasi yang adaptif terhadap teknologi.
                </p>
                <p>SMA Negeri 10 Kota
                Bekasi diusianya yang cukup matang senantiasa terus
                mengembangkan prestasi menuju sekolah unggul sebagaimana
                tertuang dalam visi sekolah : "Terwujudnya Sumber Daya Manusia
                yang cerdas, terampil, kreatif, berbudaya lingkungan
                berlandaskan IMTAK dan IPTEK." 
                </p>
                <p>Keunggulan tidak hanya sebatas
                ditandai dengan capaian prestasi akademik akan tetapi secara
                integral dengan implementasi karakter. Budaya mutu menjadi satu
                kesatuan antara kualitas akademik dengan nilai-nilai karakter.
                </p>
                <p>SMAN 10 Kota Bekasi di era pandemi ini melaksanakan pembelajaran
                dari rumah (BDR) dengan sistem blended learning, kombinasi
                antara belajar dalam jaringan (daring) dengan belajar di luar
                jarigan (luring).</p>
                <p>Menghadapi masa AKB (adaptasi kebiasaan baru)
                kami tim manajemen sekolah telah menyiapkan berbagai sarana dan
                prosedur.
                </p>
                </p>
         
          
       </div>
        </div>
      </div>
    </section>
  );
}

export default Sambutan;
