import "./Visimisi.css";

import "animate.css";
function Visimisi() {
  return (
    <section className="visi-section">
      <div className="container-visimisi">
        <div className="sub-container-visimisi" data-aos="slide-up"  data-aos-duration="900">
        <div className="container-visi">
          <h4>Visi</h4>
          <p>
            "Terwujudnya Sumber Daya Manusia yang cerdas, terampil, kreatif,
            berbudaya lingkungan berlandaskan IMTAK dan IPTEK."
          </p>
        </div>

        <div className="container-misi">
          <h4>Misi</h4>
          <ol>
            <li>
              Terwujudnya Sumber Daya Manusia yang cerdas, terampil, kreatif,
              berbudaya lingkungan berlandaskan IMTAK dan IPTEK.
            </li>
            <li>
              Meningkatkan kemampuan-kemampuan manusia dalam berbagai bidang,
              seperti ilmu pengetahuan, kesehatan, dan teknologi.
            </li>
            <li>
              Meningkatkan kemandirian, ketaatan, dan ketepatan dalam mewujudkan
              kemajuan sekolah.
            </li>
            <li>
              Meningkatkan kemampuan-kemampuan manusia dalam berbagai bidang,
              seperti ilmu pengetahuan, kesehatan, dan teknologi.
            </li>
            <li>
              Menyiapkan lulusan yang mampu bersaing di masyarakat dan menuju
              jenjang pendidikan yang lebih tinggi.
            </li>
            <li>
              Mengembangkan sarana dan prasarana TIK untuk menunjang
              keberhasilan pendidikan.
            </li>
            <li>
              Menciptakan budaya baca melalui pelayanan perpustakaan yang
              optimal.
            </li>
            <li>
              Memberdayakan stakeholders dalam menciptakan lingkungan yang
              sehat, kondusif, dan alami.
            </li>
            <li>
              Mengembangkan budaya Senyum, Salam, Sapa, Sopan dan Santun (5S).
            </li>
          </ol>
        </div>
        </div>
      </div>
    </section>
  );
}

export default Visimisi;
