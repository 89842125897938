import "./Berita.css";

function Berita() {
  return (
    <section>
      <div className="container-berita-all">
        <div className="sub-title-container" data-aos="fade-down">
          <h3>Berita Terkini</h3>
          <small>
            Ketahui informasi-informasi seputar SMA Negeri 10 Kota Bekasi di "Berita"
          </small>
        </div>

        <div className="container-all">
          <div className="card"  data-aos="fade-up" data-aos-duration="900">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/berita/2024/07/09/1.jpg"
              className="card-img-top"
              alt="INFO Pra MPLS 2024"
            />
            <div className="card-body">
              <h2 className="card-title">INFO Pra MPLS 2024</h2>
              <p className="card-text">
                Bekasi, 9 Juli 2024 Pemberitahuan untuk siswa - siswi peserta didik baru untuk melihat informasi …
              </p>
              <a href="#" className="btn btn-primary" id="berita-button">
                Lebih banyak
              </a>
            </div>
          </div>

          <div className="card" data-aos="fade-up" data-aos-duration="900">
            <img
              src="https://www.sman10bekasi.sch.id/static/img/berita/2024/07/05/Daftar_Ulang_PPDB_Tahap_2_2024_yP3U6Na.jpg"
              className="card-img-top"
              alt="PENGUMUMAN TAHAP 2 & DAFTAR ULANG 2024"
            />
            <div className="card-body">
              <h2 className="card-title">PENGUMUMAN TAHAP 2 & DAFTAR ULANG 2024</h2>
              <p className="card-text">
                Bekasi, 5 Juli 2024 Pengumuman hasil seleksi PPDB akan diumumkan pada tanggal 5 Juli 2…
              </p>
              <a href="#" className="btn btn-primary" id="berita-button">
                Lebih banyak
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Berita;
