import React, { useEffect, useState, useRef } from 'react';
import './Counter.css'; // Import the CSS file

const Counter = ({ targetNumber = 100, speed = 50, description = "", logoSrc = "" }) => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const counterRef = useRef(null); // Reference to the counter element

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the counter element is intersecting
          if (entry.isIntersecting) {
            startCounter(); // Start counting
            observer.unobserve(entry.target); // Stop observing after triggering
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (counterRef.current) {
      observer.observe(counterRef.current); // Observe the counter element
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current); // Clean up observer on unmount
      }
    };
  }, []);

  const startCounter = () => {
    const interval = setInterval(() => {
      setCurrentNumber((prevNumber) => {
        if (prevNumber < targetNumber) {
          return prevNumber + 1; // Increment the number
        } else {
          clearInterval(interval); // Stop the counter when the target is reached
          return prevNumber; // Return the final number
        }
      });
    }, speed); // Interval for updating the counter
  };

  return (
    <div
      ref={counterRef} // Attach the ref to the counter element
      className="counter" // Use the CSS class
    >
      <div className="number">{currentNumber}</div>
      <div className="counter-logo-description">
        {logoSrc && <img src={logoSrc} alt={description} className="counter-logo" />} {/* Render logo if logoSrc is provided */}
        <span className="description">{description}</span>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="counters-container">
      <Counter id="counter-count" targetNumber={1058} speed={50} description="Siswa" logoSrc="https://www.svgrepo.com/show/324120/graduation-education-cap-mortarboard-graduate.svg" />
      <Counter id="counter-count" targetNumber={56} speed={50} description="Guru" logoSrc="https://www.svgrepo.com/show/216231/couple.svg" />
      <Counter id="counter-count" targetNumber={0} speed={50} description="Tenaga pendidik" logoSrc="https://www.svgrepo.com/show/199997/users-employees.svg" />
    </div>
  );
};

export default App;
