import TypedComponent from "./Typed";
import "./Home.css";
function Home() {
  return (
    <section id="home">
    <div classname="container-home">
      <div className="container-secondary-level">
        <div className="sub-container" data-aos="fade-right" data-aos-duration="900">
      <div className="headline">
        <TypedComponent />
      </div>
      <div className="sub-title">
        <small>
        <strong className="bold">SMAN 10 Bekasi</strong> adalah salah satu sekolah menengah atas negeri di Bekasi, Jawa Barat, yang menawarkan pendidikan berkualitas. Sekolah ini memiliki berbagai program studi dan fasilitas yang mendukung pengembangan akademik dan keterampilan siswa. Dengan kurikulum yang sesuai standar nasional, SMAN 10 Bekasi mempersiapkan siswa untuk pendidikan tinggi dan karir masa depan. Selain itu, sekolah ini juga aktif dalam berbagai kegiatan ekstrakurikuler untuk mendukung perkembangan holistik siswa.
        </small>
      </div>
      <div className="buttons">
      <a class="btn btn-primary" id="btn-portal" href="#" role="button">Portal PPDB</a>
      <a class="btn btn-primary" id="btn-berita" href="#" role="button">Berita kami</a>
      </div>
      </div>
      </div>
    </div>
    </section>
  )
}

export default Home;
