import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
function Footer() {
  return (
    <section>
      <div className="footer" data-aos="fade-up" data-aos-duration="700">
        <div className="container-header-footer">
      <div className="header-footer">
            <h2>SMAN 10 KOTA BEKASI</h2>
            </div>
            </div>
      <div className="container">
        <div className="footer-section about">
          <h3>TENTANG KAMI</h3>
          <p>
            Dahulunya SMAN 10 Kota Bekasi merupakan filial dari SMA Negeri 4 Kota Bekasi, kemudian memisahkan diri menjadi sekolah independen dan terbentuklah SMAN 10 Kota Bekasi. setelah beberapa pergantian tahun, kami mulai berbenah dan sekarang ini menjadi sekolah yang asri dan memiliki empat gedung utama.
          </p>
        </div>
        <div className="footer-section quick-links">
          <h3>QUICK LINKS</h3>
          <ul>
            <li><a href="#">Berita</a></li>
            <li><a href="#">Kontak Kami</a></li>
            <li><a href="#">Ekstrakurikuler</a></li>
          </ul>
        </div>
        <div className="footer-section related-links">
          <h3>WEBSITE TERKAIT</h3>
          <ul>
            <li><a href="#">Rumah Belajar</a></li>
            <li><a href="#">Disdik Jawa Barat</a></li>
            <li><a href="#">PSMA Kemendikbud</a></li>
            <li><a href="#">SNMPTN</a></li>
          </ul>
        </div>
        <div className="footer-section related-links">
          <h3>TEMUKAN KAMI</h3>
          <div className="social-media">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
        </div>
      </div>
    </div>
    <div className="copyright-container">
      <div className="sub-copyright-container">
        <div className="copy">
          <p>SMAN 10 BEKASI WEBSITE BY OPTION © 2024-2025 All rights reserved.</p>
        </div>
      </div>
    </div>
    </section>
  );
}

export default Footer;
