import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import './Typed.css'

const TypedComponent = () => {
  // Membuat ref untuk menyimpan elemen yang akan diinisialisasi Typed.js
  const typedElement = useRef(null);

  useEffect(() => {
    // Konfigurasi Typed.js
    const options = {
      strings: ['Selamat datang di website sekolah ini!', 'Sekolah terbaik untuk anak-anak anda!', 'Daftarkan sekarang juga!'],
      typeSpeed: 50,
      backSpeed: 35,
      loop: true,
      cursorChar: '_',
    };

    // Inisialisasi Typed.js
    const typed = new Typed(typedElement.current, options);

    // Membersihkan Typed.js saat komponen di-unmount
    return () => {
      typed.destroy();
    };
  }, []);

  return <span ref={typedElement}  className="highlighted-text"></span>;
};

export default TypedComponent;
