import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Navbar from './components/Navbar';
import Ekstra from './components/Ekstrakurikuler';
import Home from './components/Home';
import Galerifoto from './components/Foto';
import './App.css';
import Sambutan from './components/Sambutan';
import Visimisi from './components/Visimisi';
import Counter from './components/Counter';
import Berita from './components/Berita';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
 <Router>
      {/* The Navbar remains visible on all pages */}
      <Navbar />
      
      {/* Define routes for specific pages here */}
      <Routes>
        <Route path="/Foto" element={<Galerifoto />} />
      </Routes>
    </Router>
      <div class="overlay"></div>
      <Home />
      <Sambutan />
      <Visimisi />
      <Ekstra />
      <Counter />
      <Berita />
      <Footer />
    </div>
  );
}

export default App;
